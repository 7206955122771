import { required, maxLength, minLength } from "vuelidate/lib/validators";
import { onlyAlphanumeric, inputColor, onlyMayusText } from '@/_validations/validacionEspeciales';

export default () => {
    return {
        TpHolidayName: { required, onlyMayusText, maxLength: maxLength(250) },
        Color: { required, inputColor, minLength: minLength(3), maxLength: maxLength(7) },
    }
}

