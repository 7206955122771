<template>
  <div>
    <CModalExtended
      size="lg"
      :title="tituloModal"
      color="dark"
      scrollable 
      :closeOnBackdrop="false"
      :show.sync="ActualizaFeriado"
    >
      <CustomTabs :active-tab="pestania">
        <CustomTab :title="$t('label.holidayType')">
          <template #title>
            <img class="mr-1" width="100%" height="100%" src="/img/iconos/Tab-Holiday-Type.svg" alt="Card image cap">
            {{$t('label.holidayType')}}                
          </template>
          <TipoFeriadoModal @addTipo="addTipo" />
        </CustomTab>
        <CustomTab :title="$t('label.holidayDay')">
          <template #title>
            <img class="mr-1" width="100%" height="100%" src="/img/iconos/Tab-Holiday.svg" alt="Card image cap">
            {{$t('label.holidayDay')}}                
          </template>
          <DiaFeriadoModal :NuevoTipo="refrescarTipo" :periodo="periodo" @TipoVacio="refrescarTipo=false" />
        </CustomTab>
      </CustomTabs>
      <template #footer>
        <CButton color="wipe" @click="salir()">
          <CIcon name="x"/>&nbsp;
          {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
    
  </div>  
</template>

<script>
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import TipoFeriadoModal from './tipo-feriado-modal';
import DiaFeriadoModal from './dia-feriado-modal';

//DATA
function data () {
  return {
    //VARIABLES
    ActualizaFeriado: false,
    refrescarTipo: false,
    refrescarCalendario: false,
    pestania: 0,
    periodo: 0,
    tituloModal: ''
  }
}

function addTipo(newVal) {
  this.refrescarTipo = true;
}

function salir() {
  this.ActualizaFeriado=false;
  this.refrescarCalendario=true;
}

export default {
  name: 'feriado-modal',
  components:{ 
    CustomTabs,
    CustomTab,
    TipoFeriadoModal,
    DiaFeriadoModal
  },
  data,
  props: {
    modal: null
  },
  watch: {
    modal: function () {
      if (this.modal) {
        this.ActualizaFeriado = true;
        this.periodo = this.modal.periodo;
        this.tituloModal = "FERIADO ("+this.periodo+")";
        this.$emit('cerrarModal');
        if (this.modal.item) {
          this.pestania = 1;
          this.refrescarTipo = this.modal.item;
        }
      }
    },
    refrescarCalendario: function () {
      if (this.refrescarCalendario) {
        this.$emit('child-refresh', true);
      }
    }
  },
  methods: {
    addTipo,
    salir
  },
}
</script>
<style scoped>

</style>